import React from "react"
import {Link} from "gatsby"
import SiteLayout from "../components/sitelayout"
import Header from "../components/header"
import SEO from "../components/seo"
import Button from "../components/button"
import Container from "../components/container"
import TextDiv from "../components/textdiv"
import Section from "../components/section"
import Segment, {SegmentSummary, SegmentSubTitle} from "../components/segment"
import HelpLink from "../components/helplink"
import ExternalLink from "../components/externallink"

import { useTheme } from "../services/theme"

const Terms = () => {

    const theme = useTheme()
    return (
        <SiteLayout
        signup={
            <Link to="/tools/hubspot-webflow-form-tool"><Button secondary="true">Get free tools</Button></Link>
        }
        >
            <SEO title="Terms"/>
            <Container>
                <Header
                paddingTop="20px"
                title="Terms of use"
                align="left"
                />
            </Container>
            <Section>
                <p>
                    Please read and accept these terms of use, the
                    <HelpLink to="/privacy" display="inline" fontWeight="600"> privacy policy </HelpLink>and the
                    <HelpLink to="/dpa" display="inline" fontWeight="600"> data processing agreement </HelpLink>
                     before using this website or any of our services (collectively "Services").
                     These terms of use assert the legally binding terms and conditions for any use of the Services.
                     If you have suggestions or questions your feedback is welcome at legal@vimkit.com.
                </p>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            What we mean when we say...
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Definitions</SegmentSubTitle>
                    <p>
                        We provide founders, designers and marketers with the software and infrastructure to add functionality to their websites.
                        This includes but is not limited to: authenticating users, uploading and retrieving user data, processing credit card payments,
                        sending email and text notifications and integrating with third party APIs.
                        When we say “our”, “we” or “us” we mean Jensa Ventures Inc, a Delaware corporation.
                        When we say "you", "your" or “Customer” we mean the person or entity using the Service
                         and identified as such in the account registration, billing statement or online subscription process.
                        When we say “your data” or “your content” or "Customer Data" or "Customer Site" we mean your website, any data you upload,
                         or send via the Service.
                        When we say “Terms” we mean these terms of use.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            To use Vimkit, you must agree to the Terms.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Acceptance</SegmentSubTitle>
                    <p>
                        You must agree to these Terms before using the Services.
                        When we make significant changes to these Terms, we’ll notify you via the site or with the email you provided.
                        You may send a request to cancel your account to support@vimkit.com at any time.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You must be 13 years or older.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Age restrictions</SegmentSubTitle>
                    <p>
                        This Service should only be used by adults, meaning you and your users must be at least 13-years old or older to use it.
                        We will delete any information we find was collected from a user under the age of 13 as quickly as possible.
                        If you need to delete your account, or delete the account of a child under the age of 13 please contact us at support@vimkit.com.
                         By using our Services you represent and warrant that you are at least 13 years of age.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You need to create an account to use Vimkit.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Account registration</SegmentSubTitle>
                    <p>
                        In order to access and use all of the features of the Service, you are required to open an account (“Account”)
                         by registering with us. When you register for your Account you must provide true, accurate, current and complete information,
                         and you agree to update the information in order to ensure that it is current. Upon proper registration and opening of a Account,
                         and subject to these Terms, we hereby grant to you the personal, non-transferable right and license to use the Service,
                         solely for your own internal business purposes, until such time as either you or we elect to terminate such right
                         in accordance with these Terms.
                    </p>
                    <p>
                        Eligibility:<br/>
                        As an express condition of being permitted to open an Account, you represent and warrant that you 
                        <ul>
                            <li>
                            have the legal capacity to enter into contracts under the law of the jurisdiction in which you reside
                            </li>
                            <li>
                            are not on a list of persons barred you from receiving services under U.S. laws
                                (including, without limitation, the Denied Persons List and the Entity List issued by the
                                U.S. Department of Commerce, Bureau of Industry and Security) or other applicable jurisdiction 
                            </li>
                            <li>
                            are not a resident of Cuba, Iran, North Korea, Sudan or Syria
                            </li>
                        </ul>
                    </p>
                    <p>
                        Credentials: <br/>
                        Upon registration for an Account, you will provide us with a email ID and password or a federated login credential to access your Account.
                         You are responsible for maintaining the confidentiality of your password or federated login credential and for all of your activities
                         and those of any third party that occur through your account, whether or not authorized by you.
                         You agree to immediately notify us of any suspected or actual unauthorized use of your Account.
                         You agree that we will not under any circumstances be liable for any cost, loss, damages or expenses arising
                         out of a failure by you to maintain the security of your password or federated login credential.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Don't abuse Vimkit.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Prohibited actions</SegmentSubTitle>
                    <p>
                        By registering for the Service you agree to not do any of the following:
                    </p>
                    <ul>
                        <li>Threaten others with violence.</li>
                        <li>
                            Accept payments in exchange for any categories of businesses and business practices that are 
                            <ExternalLink to="https://stripe.com/us/restricted-businesses" display="inline" fontWeight="600">
                                restricted from using Stripe
                            </ExternalLink>.
                        </li>
                        <li>Use hateful, abusive, harassing, libelous, or obscene language towards others.</li>
                        <li>Advertise, promote or solicit for or related to any product or service
                             that is competitive to our Service.
                        </li>
                        <li>Use the Service, transmit or transfer (by any means) information or software derived from the Service
                             to foreign countries or certain foreign nations in violation of US export control laws
                             including but not limited to the U.S. Export Administration Act of 1979
                             and the regulations of the U.S. Department of Commerce.
                        </li>
                        <li>
                            Initiate or send any telephonic or electronic communications in connection with your use of the Service
                             that does not comply with all applicable foreign and US federal or state laws and regulations,
                             including without limitation the Telephone Consumer Protection Act.
                        </li>
                        <li>
                            Upload, publish, or distribute any material that infringes or violates any third party’s copyright,
                            trademark, trade secret, privacy, or other proprietary or property right.
                        </li>
                        <li>
                            Upload, publish, or distribute any material which promotes illegal activity, could constitute a criminal offense,
                            give rise to civil liability, or otherwise violate any applicable law or regulation.
                        </li>
                        <li>
                            Upload, publish, or distribute any material that promotes racism, bigotry,
                            hatred or physical harm of any kind against any group or individual.
                        </li>
                        <li>
                            Upload, publish, or distribute any material which is defamatory, inaccurate, abusive, profane,
                            child pornographic, offensive, obscene or otherwise objectionable.
                        </li>
                        <li>
                            Upload, publish, or distribute video, audio, photographs, or images of another person
                            without his or her permission (or in the case of a minor, the minor’s legal guardian).
                        </li>
                        <li>Use the Service to send spam, chain letters, junk mail, or any other type of unsolicited mass email.</li>
                        <li>Use the Service to distribute viruses or other harmful, disruptive, or destructive files.</li>
                        <li>Use or attempt to use another person’s account.</li>
                        <li>Disrupt or interfere with the security of, or otherwise abuse, the Service, or any servers or networks connected to the Servie.</li>
                        <li>Attempt to obtain unauthorized access to or at the Service.</li>
                        <li>Impersonate another person.</li>
                        <li>Make the Service, in whole or in part, available to any other person, entity, or business except as expressly authorized by us</li>
                        <li>
                            Copy, reverse engineer, decompile, or disassemble any code or functionality of or at the Service,
                            in whole or in part, or otherwise attempt to discover the source code to any software used in or at the Service
                        </li>
                        <li>
                            Modify the Service or any associated software or combine the Service with any other software
                            or services not provided or approved by us.
                        </li>
                        <li>Share with any minor any content or materials inappropriate for children, or allow any minor access to such materials.</li>
                        <li>Systematically harvest data from the site, or programmatically register Accounts on the Service.</li>
                    </ul>
                    <p>
                        You agree to accept responsibility for any of your actions in relation to the Service.
                        You agree to comply with local, state, national, and international laws and regulations.
                        This includes, without limitation those laws related to data collection/processing, email delivery and creation, and privacy.
                        If we believe you have broken any of these rules, we will, at our sole discretion, delete offending material,
                        place restrictions on or delete your Account.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Some features are only available on paid plans.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Fees and term</SegmentSubTitle>
                    <p>
                        Fees:<br/>
                        Some features of the Service may only be accessed and used upon the payment of applicable fees (“Fees”).
                         Fees may vary depending on usage in accordance with our current pricing policy available in the Service.
                         If you do not initially register for a version of the Service that requires the payment of a fee,
                         you will nonetheless be permitted to use all of the features of the Service for the trial period expressly
                         stated at the time you signed up for the trial period use of the Service (“Free Trial Period”).
                         Any Service provided during the free trial period or as part of free Services is provided
                         “as-is” without any representations, warranties or indemnities.<br/>
                        Upon the expiration of the Free Trial Period, you will only be able to access and use those features of the Service
                         that do not require the payment of a Fee, unless you subsequently upgrade to a paid version of the Service.
                         All Fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you are solely responsible
                         for payment of all such taxes, levies, or duties, excluding only United States (federal or state) taxes.
                    </p>
                    <p>
                        Refunds, Upgrading and Downgrading: <br/>
                        Refunds are processed according to our fair refund policy.
                         Any changes in your Service usage that result in any new, increase or decrease in Fees as specified in our
                         current pricing policy available in the Service, will be charged at the next billing cycle.
                         There will be no prorating for downgrades in between billing cycles. Downgrading your Service may cause the
                         loss of features or capacity of your Account. We do not accept any liability for such loss.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You can cancel anytime.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Canceling your account</SegmentSubTitle>
                    <p>
                        You may cancel your Account, or your user’s accounts at any time and your discretion.
                         You are solely responsible for properly canceling your Account.
                         An email or phone request to cancel your Account is not considered cancellation.
                         You can cancel your Account at any time by clicking on the profile icon at the top right of the Service.
                         If you cancel your Account before the end of your current paid up applicable subscription term,
                         your cancellation will take effect immediately, and you will not be charged again.
                         Please note that we do not provide refunds for unused time in the last billing cycle.
                         Once your Account is cancelled, we may permanently delete your Account and any or all Customer Data associated with it.
                         If you do not log in to your User Account for 12 or more months, we may treat your Account as “inactive”
                         and permanently delete the Account and all the Customer Data associated with it.
                         All sections of these Terms which by their nature should survive termination will survive, including without limitation,
                         accrued rights to payment, use restrictions and indemnity obligations, confidentiality obligations, warranty disclaimers,
                         and limitations of liability.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We can terminate at anytime too.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Right to terminate</SegmentSubTitle>
                    <p>
                        We may at any time decide to alter, amend, modify, or terminate the site, any functionality or portion of it,
                         all in our sole discretion, and you understand that there is no guarantee that the Service or any portion
                         or functionality of it will continue to operate or be available for any particular period of time.
                         If we terminate your Account without cause, we will refund a prorated portion of your monthly prepayment.
                         We will not refund or reimburse you if we terminate your Account for cause, including (without limitation)
                         for a violation of these Terms.
                         Except where an exclusive remedy may be specified in these Terms, the exercise by either party of any remedy,
                         including termination, will be without prejudice to any other remedies it may have under these Terms.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Don't violate intellectual property rights.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Copyright</SegmentSubTitle>
                    <p>
                        We respect the intellectual property rights of others, and we ask our users to do the same.
                         We may, at our sole discretion, suspend the access or terminate the accounts of users who violate others'
                         intellectual property rights.
                         Pursuant to Title 17, United States Code, Section 512(c)(2), notifications of claimed copyright infringement
                         on our Service should be sent to our Copyright Agent. The  Copyright Agent for notice of claims of copyright infringement
                         can be reached either by sending an e-mail to legal@vimkit.com or by sending a letter via U.S. Mail to:
                         Jensa Ventures Inc., 2093 Philadelphia Pike, #8070 Claymont, DE 19703 USA
                    </p>
                    <p>
                        To be effective, the notification must include the following:
                        <ol type="a">
                            <li>
                                A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right
                                 that is allegedly infringed 
                            </li>
                            <li>
                                Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted works
                                 at a single online site are covered by a single notification, a representative list of such works at that site
                            </li>
                            <li>
                                Identification of the material that is claimed to be infringing or to be the subject of infringing activity
                                 and that is to be removed or access to which is to be disabled, and information reasonably sufficient
                                 to permit us to locate the material
                            </li>
                            <li>
                                Information reasonably sufficient to permit us to contact you, such as an address, telephone number,
                                 and if available, an electronic mail address at which the complaining party may be contacted
                            </li>
                            <li>
                                A statement that you have a good faith belief that use of the material in the manner complained of is not authorized
                                 by the copyright owner, its agent, or the law
                            </li>
                            <li>
                                A statement that the information in the notification is accurate, and under penalty of perjury,
                                 that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                            </li>
                        </ol>
                    </p>
                    <p>
                        Trademarks:<br/>
                        Vimkit and the Vimkit Logo are trademarks and services marks of Vimkit (collectively "Vimkit Trademarks”)
                         and third-party logos and product names are trademarks and service marks of third-parties (collectively “Third-Party Trademarks”)
                         and nothing in these Terms shall be construed as granting any license or right to use the Vimkit Trademarks
                         without our prior written consent or the Third-Party Trademarks without the third-party's consent.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We'll maintain confidentiality and expect you to do so too.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Mutual confidentiality</SegmentSubTitle>
                    <p>
                        Each party (“Disclosing Party”) may disclose “Confidential Information” to the other party (“Receiving Party”)
                         in connection with the Service, which is anything that reasonably should be understood to be confidential
                         given the nature of the information and the circumstances of disclosure, as well as non-public business,
                         product, technology and marketing information. If something is labeled “Confidential,” that’s a clear indicator
                         to the Receiving Party that the material is confidential. Confidential Information does not include information that
                         is or becomes generally available to the public without breach of any obligation owed to the Disclosing Party or
                         was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation
                         owed to the Disclosing Party or is received from a third party without breach of any obligation owed to the Disclosing Party or
                         was independently developed by the Receiving Party.
                    </p>
                    <p>
                        The Receiving Party will: 
                        <ul>
                            <li>
                                protect the confidentiality of the Confidential Information of the Disclosing Party using the same degree of care
                                 that it uses to protect the confidentiality of its own Confidential Information of like kind,
                                 but in no event less than reasonable care,
                            </li>
                            <li>
                                not use any Confidential Information of the Disclosing Party for any purpose outside the scope of these Terms
                            </li>
                            <li>
                                not disclose Confidential Information of the Disclosing Party to any third party
                                 (except those third-party service providers used by us to provide some or all elements of the Service)
                            </li>
                            <li>
                                limit access to Confidential Information of the Disclosing Party to those of its and its affiliates' employees,
                                 contractors and agents who need such access for purposes consistent with these Terms and who have signed
                                 confidentiality agreements with the Receiving Party containing protections no less stringent than those herein
                            </li>
                        </ul>  
                    </p>
                    <p>
                        The Receiving Party may disclose Confidential Information of the Disclosing Party if required to do so under any federal,
                         state, or local law, statute, rule or regulation, subpoena or legal process; provided, however, that 
                        <ul>
                            <li>
                                the Receiving Party will provide the Disclosing Party with prompt notice of any request that it disclose
                                 Confidential Information, sufficient to allow the Disclosing Party to object to the request and/or
                                 seek an appropriate protective order or, if such notice is prohibited by law,
                                 the Receiving Party will disclose the minimum amount of Confidential Information required to be disclosed
                                 under the applicable legal mandate
                            </li>
                            <li>
                                in no event will the Receiving Party disclose Confidential Information to a party other
                                 than a government agency except under a valid order from a court having jurisdiction requiring the specific disclosure.
                            </li>
                        </ul>  
                    </p>
                    <p>
                        Nothing above will prevent either party from sharing Confidential Information with financial and legal advisors provided,
                         however, that the advisors are bound to confidentiality obligations at least as restrictive as those in the Terms.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We can tell others that you're a customer.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Publicity</SegmentSubTitle>
                    <p>
                        You grant us the right to add your name and company logo to our customer list and website.
                         You can opt-out by emailing us at legal@vimkit.com.  
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You own and are responsible for your content.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Your content</SegmentSubTitle>
                    <p>
                        You retain ownership of any Customer Data that that you transmit through the Service – for example,
                         data from your accounts with Third Party Services that passes through the Service. By transmitting Customer Data through
                         the Service, you hereby grant to us worldwide, non-exclusive, limited term license to access, use, process, copy, distribute,
                         perform, transmit, export and display Customer Data and to access your Account, as reasonably necessary to provide,
                         maintain, operate and update the Service and to provide customer support for the Service,
                         to prevent or address service, security, support or technical issues, or as required by law..  
                    </p>
                    <p>
                        Public posts:<br/>
                        The Service may include functionality that permits you to post, upload or transmit content in a manner
                         that is intended to be viewed by other users ("Public Posts"). For example, examples of how you use the Service to achieve a 
                         particular business objective. By submitting Public Posts to the Service, you hereby grant us a perpetual, irrevocable,
                         royalty-free, worldwide, non-exclusive right and license, including the right to grant sublicenses to third parties,
                         to use, reproduce, publicly display, publicly perform, prepare derivative works from and distribute Public Posts for any purpose.
                         We may exercise all copyright, publicity and moral rights, in any media, with respect to Public Posts.
                    </p>
                    <p>
                        No prohibited sensitive personal data:<br/>
                        You may not access or use the Service to post, upload or transmit, or incorporate any data that is subject to heightened privacy
                         and security requirements by law or regulations or applicable Third Party Services terms, including, without limitation,
                         any financial or medical information of any nature, any sensitive personal information (e.g., government issued numbers,
                         driver’s license numbers, birth dates, personal bank account numbers, passport or visa numbers and credit card numbers),
                         or any special categories of personal data under GDPR.
                    </p>
                    <p>
                        Enforcement:<br/>
                        We reserve the right, but do not undertake the obligation, to monitor the Service and to investigate
                         and take appropriate legal action against any party that uses the Service in violation of applicable law
                         or these Terms. We reserve the right to accept, reject or modify any Customer Data or Public Posts
                         but assume no liability based on our acceptance, rejection, modification or failure to modify any Customer Data or Public Posts.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We retain all rights to Vimkit.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Proprietary rights</SegmentSubTitle>
                    <p>
                        The Service is made available on a limited access basis, and no ownership right is conveyed to you.
                         We and our licensors have and retain all right, title and interest, including all intellectual property rights,
                         in and to the Service, including all modifications, updates, upgrades, extensions, components and all derivative works
                         to the Service. All our rights not expressly granted under these Terms are hereby retained.
                    </p>
                    <p>
                        From time to time at your sole discretion, you may choose to provide suggestions, enhancement requests, recommendations
                         or other feedback related to the Service (“Suggestions”) to us. You hereby grant us a royalty-free, worldwide, transferable,
                         sublicenseable, irrevocable, perpetual license to use or incorporate any Suggestions into the Service and/or other products,
                         services or offerings we may create. Notwithstanding anything to the contrary set forth herein or otherwise,
                         we will have the right to collect and analyze data and other information relating to the provision,
                         use or performance of the Service and related systems and technologies and to aggregate and/or de-identify
                         all such data and information. We will be free at any time to use such information and data to improve
                         and enhance the Service and disclose such data in aggregate or other de-identified form in connection with its business.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            You are responsible for any third party services you use with Vimkit.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Linked Third Party Services</SegmentSubTitle>
                    <p>
                        The Service may contain links to and integrations with third party websites, applications and services (“Third Party Services”),
                         and you agree that we provide links to and integrations with such Third Party Services solely as a convenience
                         and have no responsibility for the content or availability of such Third Party Services,
                         and that we does not endorse such Third Party Services (or any products or other services associated therewith).
                         Access to any Third Party Services linked to the Service is at your own risk, and we are not responsible for the accuracy
                         or reliability of any information, data, opinions, advice or statements made on such Third Party Services.
                         Your use of such Third Party Services will be subject to the terms applicable to each such Third Party Services.
                         You may not post a link that directs users to any content or information that would constitute a violation of these Terms
                         or any terms applicable to such Third Party Services.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Use at your own risk.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Disclaimer of warranties</SegmentSubTitle>
                    <p>
                        We will work hard to take care of your data and prevent interruptions to the Service.
                        However, OUR SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
                        WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
                        We do not make any warranty that the Service will meet your requirements,
                        or that the Services will be uninterrupted, timely, secure, or error-free, or that defects, if any, will be corrected.
                        YOU UNDERSTAND THAT YOU INTERACT WITH OR OTHERWISE USE THE SERVICES AT YOU OWN DISCRETION AND RISK.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Our liability is limited to what you've paid us in the last year.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Limitations of liability</SegmentSubTitle>
                    <p>
                        UNDER NO CIRCUMSTANCES — INCLUDING, WITHOUT LIMITATION, NEGLIGENCE — SHALL WE BE LIABLE FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, RESULTING FROM 
                        (1) THE USE OR THE INABILITY TO USE THE SERVICE;
                        (2) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES;
                        (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
                        (4) LOSS OF PROFITS, USE, DATA OR OTHER INTAGIBILES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        The foregoing shall not apply to the extent prohibited by applicable law.
                    </p>
                    <p>
                        You will not hold us responsible for any capabilities or content made available through your site.
                        The Service allows you to add capabilities and third party APIs to your website.
                        We are not involved in the actual setup or usage of these capabilities on your website.
                        We have no control over and do not guarantee the quality, safety or legality of the capabilities on your website,
                        the truth or accuracy of your content, the ability to sell or buy, send or receive communications or transfer data to other services.
                    </p>
                    <p>
                        OUR TOTAL CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING FROM OR IN CONNECTION WITH THESE TERMS AND/OR THE SERVICE 
                        (UNDER ANY LEGAL THEORY, INCLUDING WITHOUT LIMITATION CLAIMS IN CONTRACT OR TORT) 
                        WILL NOT EXCEED THE AMOUNTS ACTUALLY PAID TO US BY YOU IN THE TWELVE (12) MONTH PERIOD
                        IMMEDIATELY PRECEDING YOUR FORMAL WRITTTEN NOTICE OF THE CLAIM FOR LIABILITY HEREUNDER. 
                        ALL CLAIMS THAT YOU MAY HAVE AGAINST US WILL BE AGGREGATED TO SATISFY THIS LIMIT 
                        AND MULTIPLE AND/OR SUBSEQUENT CLAIMS WILL NOT ENLARGE THIS LIMIT.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            Don't drag us into your lawsuits.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Indemnification</SegmentSubTitle>
                    <p>
                        YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS US, OUR OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS,
                        PARTNERS, AGENTS, AND SUPPLIERS, AND THEIR RESPECTIVE AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES,
                        MEMBERS, SHAREHOLDERS, PARTNERS, AND AGENTS, FROM ANY AND ALL CLAIMS AND EXPENSES, INCLUDING ATTORNEYS' FEES,
                        ARISING OUT OF YOUR USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO YOUR VIOLATION OF THESE TERMS.
                        We may, at our sole discretion, assume the exclusive defense and control of any matter subject to indemnification by you.
                        The assumption of such defense or control by us, however, shall not excuse any of your indemnity obligations.
                    </p>
                </Segment>
                <Segment
                    header={
                        <SegmentSummary color={theme.colors.subtleText} marginBottom="10px" paddingRight="20px">
                            We'll settle disputes based on these terms.
                        </SegmentSummary>
                    }
                    headerWidth="20%"
                    contentWidth="80%"
                    paddingTop="40px"
                >
                    <SegmentSubTitle>Dispute resolution, enforcement, waiver and relationship</SegmentSubTitle>
                    <p>
                        These Terms shall be governed by and construed in accordance with the laws of the state of California,
                        without giving effect to its conflict of law provisions. You shall resolve any claims through final and binding arbitration, 
                        except as set forth below. The Judicial Arbitration and Mediation Service (“JAMS”) will administer the arbitration 
                        under its Commercial Arbitration Rules. The arbitration will be held in Irvine, California.
                        However, you may sue in the federal or state courts of Orange County, California solely for injunctive relief to stop unauthorized use
                        or abuse of the Services or infringement of Intellectual Property Rights, and you also agree to submit to the personal
                        and exclusive jurisdiction of those courts.
                        You agree that any claim or cause of action arising out of your use of the Service or these Terms
                        must be filed within one year after such claim or cause of action arose or it shall be forever barred,
                        notwithstanding any statute of limitations or other law to the contrary.
                        If any provision contained in these Terms is determined unenforceable,
                        then such provision will be severed and replaced with a new provision that most closely reflects the intent of the original provision,
                        and the remaining provisions of these Terms will remain in full force and effect.
                        No waiver of any provision of these Terms shall be effective except pursuant to a written instrument signed by us
                        expressly waiving compliance, and any such waiver shall be effective only in the specific instance
                        and for the specific purpose stated in such writing. 
                    </p>
                    <p>
                        You agree that no joint venture, partnership, employment, or agency relationship exists between you
                        and us as a result of these Terms and/or your use of the Services.
                        You may not assign any right, interest, or benefit provided under these Terms or through the Service
                        without our express prior written consent. These Terms set forth the entire agreement between you and us,
                        and supersede any and all prior communications, agreements and proposals, whether electronic, oral or written,
                        between you and us with respect to the Services.
                        A printed version of these Terms and of any notice given in electronic form shall be admissible in judicial
                        or administrative proceedings based upon or relating to these Terms and/or your use of the Service to the same extent
                        and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
                    </p>
                </Segment>
                <TextDiv 
                    color={theme.colors.extraSubtleText} 
                    marginTop="20px" 
                    marginBottom="20px" 
                    breakpoint={theme.breakpoints.mobile}
                    responsiveAlign="center"
                >
                    Last updated on Feb 19, 2022.
                </TextDiv>
            </Section>
        </SiteLayout>    
    )
}

export default Terms
